import React, { useState } from "react";
import styles from "./CustomScrollbar.module.css";
import MessagesTable from "./MessageTable";
import DocumentsTable from "./DocumentTable";
import Dashboardbooking from './DashboardBooking';
import { useAuth } from "../context/AuthContext";

const Dashboard = () => {
  const { bookings, updateBooking, deleteBooking } = useAuth();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingBooking, setEditingBooking] = useState(null);

  const handleEditClick = (booking) => {
    setEditingBooking(booking);
    setIsEditModalOpen(true);
  };

  return (
    <div className="p-2 ml-15 lg:p-0 lg:ml-10" >
      <h1 className={`${styles.customScrollbar} flex-1 text-gray-900`}
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            lineHeight: "32px",
            gap: "2px",
          }}
      >
        Dashboard
      </h1>
      <p className="gap-2" style={{fontSize:'17px',color: 'var(--Gray-400, #6B7280)', fontStyle:'normal',fontWeight:'500',lineHeight:'28px'}}>
        All your recent activities in one place
      </p>

      <div className="mt-6 rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <Dashboardbooking bookings={bookings} onEditClick={handleEditClick} onDeleteClick={deleteBooking} />
        </div>
      </div>

      {/* Bottom Tables */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
        <div className="bg-white rounded-lg overflow-hidden">
          <MessagesTable/>
        </div>
        <div className="bg-white rounded-lg overflow-hidden">
          <DocumentsTable/>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;