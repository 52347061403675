// import React, { useEffect, useRef, useState, useCallback } from 'react';
// import styles from './CustomScrollbar.module.css';
// import SortButton from '../Buttons/Sortdate';
// import EditingBookingModal from './EditingModal';
// import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
// import { useAuth } from '../context/AuthContext';
// import { toast } from 'react-toastify';
// import DeleteModal from '../Modals/DeleteModal'; 
// import {fetchAllBookings ,updateBooking,deleteBooking} from '../firebase/services/bookingsData'
// import { Link } from 'react-router-dom';


// dayjs.extend(customParseFormat);
// dayjs.extend(isSameOrAfter);
// dayjs.extend(isSameOrBefore);



// const ScrollableAdminBookingTable = ({onEditClick, onDeleteClick}) => {
//     const [currentPage, setCurrentPage] = useState(1);
//     const [bookings, setBookings] = useState([]);
//     // const { bookings, fetchAllBookings, deleteBooking, updateBooking } = useAuth();
//     // const [bookings, setBookings] = useState([]);
//     const [filteredBookings, setFilteredBookings] = useState([]);
//     const [selectedAmenity, setSelectedAmenity] = useState("All Amenities");
//     const [isAmenityDropdownOpen, setIsAmenityDropdownOpen] = useState(false);
//     const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//     const [deleteItemName, setDeleteItemName] = useState('');
//     const [deleteFunction, setDeleteFunction] = useState(null);
//     const [amenities, setAmenities] = useState(["All Amenities", "Swimming pool", "Gym", "Multi-Purpose Court", "Jacuzzi & Spa", "Indoor Theatre", "Movie Lawn", "Table Tennis Room", "Banquet Hall"]);
    
//     const [searchTerm, setSearchTerm] = useState("");
//     const [sortedBookings, setSortedBookings] = useState([]);
//     const [selectedBooking, setSelectedBooking] = useState(null);
    
//     const amenityDropdownRef = useRef(null);
//     const [selectedRows, setSelectedRows] = useState([]);
//     const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//     const [editingBooking, setEditingBooking] = useState(null);
//     const [editingIndex, setEditingIndex] = useState(null);
//     const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
//     const searchRef = useRef(null);
//     const amenityRef = useRef(null);
//     const sortDateRef = useRef(null);


//     const itemsPerPage = 5;
//     const totalPages = Math.ceil(filteredBookings.length / itemsPerPage);
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = filteredBookings.slice(indexOfFirstItem, indexOfLastItem);
//     const isAllSelected = currentItems.length > 0 && selectedRows.length === currentItems.length;


//    // Fetch bookings on mount
//   useEffect(() => {
//     fetchAllBookings((fetchedBookings) => {
//       console.log(fetchedBookings);
      
//       setBookings(fetchedBookings);
//       setFilteredBookings(fetchedBookings);
//     });
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (searchRef.current && !searchRef.current.contains(event.target)) {
//         setIsSearchDropdownOpen(false);
//       }
//       if (amenityRef.current && !amenityRef.current.contains(event.target)) {
//         setIsAmenityDropdownOpen(false);
//       }
//       if (sortDateRef.current && !sortDateRef.current.contains(event.target)) {
//         // Assuming SortButton has a method to close its dropdown
//         sortDateRef.current.closeDropdown();
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);


// const filterBookings = useCallback((term, amenity) => {
//   let filtered = bookings;

//   if (term) {
//     filtered = filtered.filter((booking) =>
//       `${booking.userFirstName} ${booking.userLastName}`
//         .toLowerCase()
//         .includes(term.toLowerCase()) ||
//       booking.amenityName?.toLowerCase().includes(term.toLowerCase())
//     );
//   }

//   if (amenity && amenity !== "All Amenities") {
//     filtered = filtered.filter(
//       (booking) => booking.amenityName?.toLowerCase() === amenity.toLowerCase()
//     );
//   }

//   setFilteredBookings(filtered);
//   setCurrentPage(1);
// }, [bookings]);

// useEffect(() => {
//   filterBookings(searchTerm, selectedAmenity);
// }, [filterBookings, searchTerm, selectedAmenity]);




//     const handleDeleteSelected = () => {
//       setDeleteItemName('all selected bookings');
//       setDeleteFunction(() => async () => {
//         try {
//           for (const index of selectedRows) {
//             const bookingId = filteredBookings[index].id;
//             await deleteBooking(bookingId);
//           }
//           fetchAllBookings(setBookings);
//           setSelectedRows([]);
//           toast.success('Selected bookings deleted successfully');
//           setIsDeleteModalOpen(false);
//         } catch (error) {
//           toast.error('Failed to delete selected bookings');
//         }
//       });
//       setIsDeleteModalOpen(true);
//     };

//     const handleEditClick = (booking) => {
//       setEditingBooking(booking);
//       setIsEditModalOpen(true);
//     };

//     const handleDeleteClick = (booking) => {
//       const firstName = booking.userDetails?.firstName || 'Unknown';
//       const lastName = booking.userDetails?.lastName || 'User';
//       setDeleteItemName(`booking for ${firstName} ${lastName}`);
//       setDeleteFunction(() => async () => {
//         try {
//           await deleteBooking(booking.id);
//           setBookings(bookings.filter((b) => b.id !== booking.id));
//           toast.success("Booking deleted successfully");
//           setIsDeleteModalOpen(false);
//         } catch (error) {
//           toast.error("Failed to delete booking");
//         }
//       });
//       setIsDeleteModalOpen(true);
//     };

//     const handleSaveEdit = async (updatedBooking) => {
//       try {
//         await updateBooking(updatedBooking.id, updatedBooking);
//         fetchAllBookings(setBookings);  // Refetch bookings after update
//         // toast.success('Booking updated successfully');
//         setIsEditModalOpen(false);
//       } catch (error) {
//         toast.error('Failed to update booking');
//       }
//     };

//     const handleCloseModal = () => {
//         setIsEditModalOpen(false);
//         setEditingBooking(null);
//         setEditingIndex(null);
//     };

   

//     const truncateText = (text, charLimit = 20) => {
//       if (text.length <= charLimit) {
//         return text;
//       }
      
//       // Find the last space within the character limit
//       const lastSpace = text.lastIndexOf(' ', charLimit);
      
//       // If there's a space within the limit, cut at that space
//       if (lastSpace > 0) {
//         return text.slice(0, lastSpace) + '...';
//       }
      
//       // If there's no space (it's one long word), just cut at the character limit
//       return text.slice(0, charLimit) + '...';
//     };

//        // Helper function to parse dates
//   const parseDate = (dateValue) => {
//     if (dateValue instanceof Date) {
//       return dayjs(dateValue);
//     }
//     if (typeof dateValue === 'object' && dateValue.seconds) {
//       // Firestore Timestamp
//       return dayjs(dateValue.toDate());
//     }
//     if (typeof dateValue === 'string') {
//       return dayjs(dateValue);
//     }
//     console.error('Unrecognized date format:', dateValue);
//     return null;
//   };

    
// const handleClickOutside = (event) => {
//   if (amenityDropdownRef.current && !amenityDropdownRef.current.contains(event.target)) {
//     setIsAmenityDropdownOpen(false);
//   }
// };

// useEffect(() => {
//   document.addEventListener('mousedown', handleClickOutside);
//   return () => {
//     document.removeEventListener('mousedown', handleClickOutside);
//   };
// }, []);


  

//     const getAmenityStyle = (amenity) => {
//       switch (amenity.toLowerCase()) { // Use toLowerCase to ensure case-insensitive matching
//         case 'swimming pool':
//           return { backgroundColor: 'var(--Blue-25, #EFF6FF)', color: 'var(--Blue-700, #1E40AF)' };
//           case 'gym':
//           return { backgroundColor: 'var(--Rose-25, #FFF1F2)', color: 'var(--Rose-700, #9F1239)' };
//           case 'multi-purpose court':
//             return { backgroundColor: 'var(--Emerald-25, #F0FDF4)', color: 'var(--Emerald-700, #166534)' };
//             case 'banquet hall':
//               return { backgroundColor: 'var(--Purple-25, #FAF5FF)', color: 'var(--Purple-700, #6B21A8)' };
//               case 'party hall':
//                 return { backgroundColor: 'var(--Lime-25, #F7FEE7)', color: 'var(--Lime-500, #65A30D)' };
//                 case 'jacuzzi & spa':
//                   return { backgroundColor: 'var(--Cyan-25, #ECFEFF)', color: 'var(--Cyan-700, #155E75)' };
//                   case 'indoor theatre':
//                     return { backgroundColor: 'var(--Pink-25, #FDF2F8)', color: 'var(--Pink-700, #9D174D)' };
//                     case 'movie lawn':
//                       return { backgroundColor: 'var(--Orange-25, #FFF7ED)', color: 'var(--Orange-700, #9A3412)' };
//                       case 'table tennis room':
//                         return { backgroundColor: 'var(--Yellow-25, #FEFCE8)', color: 'var(--Yellow-700, #854D0E)' };
                    
         
       
//         default:
//           return { backgroundColor: '#F3F4F6', color: '#4B5563' }; // Default styling
//       }
//     };


  

//       // Custom scrollbar styles
//     const customScrollbarStyle = `
//     .custom-scrollbar::-webkit-scrollbar {
//       width: 2px;
//     }
//     .custom-scrollbar::-webkit-scrollbar-track {
//       background: #f1f1f1;
//     }
//     .custom-scrollbar::-webkit-scrollbar-thumb {
//       background: #888;
//     }
//     .custom-scrollbar::-webkit-scrollbar-thumb:hover {
//       background: #555;
//     }
//   `;

//   // Add new styles for responsiveness
//   const responsiveStyles = `
//     @media (max-width: 1024px) {
//       .table-container {
//         overflow-x: auto;
//       }
//       .responsive-table {
//         min-width: 800px;
//       }
//       .responsive-header {
//         flex-direction: column;
//         align-items: flex-start;
//       }
//       .responsive-header > div {
//         margin-bottom: 1rem;
//       }
//       .responsive-dropdown {
//         width: 100%;
//         max-width: 242px;
//       }
//     }
//     @media (max-width: 640px) {
//       .responsive-header {
//         padding: 1rem;
//       }
//       .responsive-search {
//         width: 100%;
//       }
//       .responsive-buttons {
//         flex-wrap: wrap;
//         justify-content: flex-start;
//       }
//       .responsive-buttons > * {
//         margin-right: 0.5rem;
//         margin-bottom: 0.5rem;
//       }
//     }
//   `;

//     const hideScrollbarStyle = {
//       scrollbarWidth: 'none',
//       msOverflowStyle: 'none',
//       '&::-webkit-scrollbar': {
//         display: 'none'
//       }
//     };

    
  
//     return (
//         <>
//          <style>{customScrollbarStyle}</style>
//          <style>{responsiveStyles}</style>
//         <div className="mt-1 bg-white border rounded-2xl overflow-hidden flex flex-col"style={{ position: 'relative', zIndex: 1 }} >
//             <div className="sticky top-0 z-10 bg-white">
//                 <div className="flex justify-between items-center p-6 ">
//                 <div className="flex justify-between items-center ">
//             <div className="text-gray-900 ">
//               <h1 className="font-bold " style={{color: 'var(--Gray-900, #030712)', fontStyle:'normal', fontWeight:'700', lineHeight:'24px',fontSize:'16px'}}>Recent Booking</h1>
//               <span className="text-gray-400 text-xs" style={{color: 'var(--Gray-400, #6B7280)', fontStyle:'normal', fontWeight:'400', lineHeight:'18px',fontSize:'12px'}}>
//                 View all your recent booking
//               </span>
//             </div>

         
            
//           </div>
//           <div className="flex justify-between">
//             <Link to="/booking" className="text-xs mr-3 font-semibold text-gray-700 underline flex items-center" style={{color: 'var(--Gray-900, #030712)', fontStyle:'normal', fontWeight:'600', lineHeight:'18px',fontSize:'12px'}}>
//   View all
//   <svg
//     className="h-4 w-2 ml-2 "
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 320 512"
//   >
//     <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
//   </svg>
// </Link>
        
//           </div>
                
                   
//                 </div>
//                 <div className="border-y bg-gray-50 table-container" >
//                     <table className="w-full text-sm text-gray-500 responsive-table">
//                         <thead className="text-xs text-gray-500 sticky top-0 z-10 bg-gray-50" style={{fontSize:'12px'}}>
//                             <tr className="border-y">
//                                 <th scope="col" className="p-4 w-4" style={{width:'2%'}}>
                                   
//                                 </th>
//                                 <th scope="col" className="py-4 px-6 text-left" style={{fontSize:'12px',width:'20%'}}>Name</th>
//                                 <th scope="col" className="px-6 py-4" style={{width:'20%'}}>
//                                     <div className="flex items-center " >
//                                         Slot time
//                                         <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
//                                             <path d="M8.66699 3.66667V12M8.66699 12L4.66699 8M8.66699 12L12.667 8" stroke="#4B5563" strokeLinecap="round" strokeLinejoin="round"/>
//                                         </svg>
//                                     </div>
//                                 </th>
//                                 <th scope="col" className="px-6 py-4 text-left" style={{width:'20%'}}>
//                                     <div className="flex items-center" >
//                                         Slot date
//                                         <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
//                                             <path d="M8.66699 3.66667V12M8.66699 12L4.66699 8M8.66699 12L12.667 8" stroke="#4B5563" strokeLinecap="round" strokeLinejoin="round"/>
//                                         </svg>
//                                     </div>
//                                 </th>
//                                 <th scope="col" className="px-6 py-4 text-left" style={{width:'20%'}}>Amenities</th>
                              
//                                 <th scope='col' className="px-6 py-4 text-right" style={{width:'18%'}}>
//                                     <span 
//                                         style={{
//                                             visibility: selectedRows.length > 1 ? 'visible' : 'hidden',
//                                             cursor: 'pointer',
//                                             color: '#EF4444'
//                                         }}
//                                         onClick={handleDeleteSelected}
//                                     >
//                                         Delete All
//                                     </span>
//                                 </th>
//                             </tr>
//                         </thead>
//                     </table>
//                 </div>
//             </div>
//             <div className="flex flex-col flex-grow overflow-hidden">
//       <div className="flex-grow overflow-y-auto" style={{ ...hideScrollbarStyle, position: 'relative', zIndex: 5 }}>
//                 <table className="w-full text-sm text-left text-gray-500 responsive-table" style={hideScrollbarStyle}>
//                     <tbody>
//                         {filteredBookings.length > 0 ? (
//                             currentItems.map((booking, index) => {
//                                 const actualIndex = indexOfFirstItem + index;
//                                 const isSelected = selectedRows.includes(actualIndex);
//                                  // Safely access userDetails properties
//                 const firstName = booking.userDetails?.firstName || 'N/A';
//                 const lastName = booking.userDetails?.lastName || 'N/A';
//                 const fullName = `${firstName} ${lastName}`;
//                                 return (
//                                     <tr key={booking.id} className="bg-white border-b hover:bg-gray-50" style={{fontSize:'14px'}}>
//                                         <td className="p-4 w-4 " style={{width:'2%'}}>
//                                             <div className="flex items-center justify-center">
//                                                 {/* <CheckboxWithTick
//                                                     isSelected={isSelected}
//                                                     onClick={() => handleRowSelect(actualIndex)}
//                                                     /> */}
//                                                 </div>
//                                             </td>
//                                             <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap  text-left" style={{width:'20%'}}>
//                                             {/* {`${booking.userDetails.firstName} ${booking.userDetails.lastName}`} */}
//                                             {/* {fullName} */}
//                                             {/* {truncateText({fullName}, 20)} */}
//                                             {truncateText(fullName, 20)}
//                                             </th>
//                                             <td className="px-6 py-4 text-left" style={{width:'20%'}}>
//                                               {/* {booking.slotTime} */}
//                                               {booking.timeSlot}

//                                             </td>
//                                             <td className="px-6 py-4 text-left" style={{width:'20%'}}>
//                                             {/* {booking.date ? new Date(booking.date.seconds * 1000).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }) : ''} */}
//                                             {booking.date ? dayjs(booking.date.toDate()).format('D MMM, YYYY') : ''}
//                                             {/* {bookingDate ? bookingDate.format('D MMM, YYYY') : 'Invalid Date'} */}
//                                               </td>
//                                             <td className="px-6 py-4 text-left " style={{width:'20%'}}>
                                               

//                                                 <span
//             className="inline-flex px-4 py-2 items-center font-semibold text-xs leading-4 rounded-xl "
//             style={{ ...getAmenityStyle(booking.amenityName), fontSize: '12px', borderRadius: '13px' }}
//           >
//             {booking.amenityName}
//           </span>
//                                             </td>
                                           
//                                             <td className="px-6 py-4 text-right" style={{width:'18%'}}>
//                                                 <div className="flex justify-end space-x-4">
//                                                     <svg onClick={() => handleEditClick(booking)} style={{cursor:'pointer'}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                         <path d="M17.7586 5.73201L14.268 2.24061C14.1519 2.1245 14.0141 2.0324 13.8624 1.96957C13.7107 1.90673 13.5482 1.87439 13.384 1.87439C13.2198 1.87439 13.0572 1.90673 12.9056 1.96957C12.7539 2.0324 12.6161 2.1245 12.5 2.24061L2.86641 11.875C2.74983 11.9906 2.65741 12.1283 2.59451 12.28C2.5316 12.4317 2.49948 12.5944 2.50001 12.7586V16.25C2.50001 16.5815 2.6317 16.8994 2.86612 17.1339C3.10054 17.3683 3.41849 17.5 3.75001 17.5H7.24141C7.40563 17.5005 7.5683 17.4684 7.71999 17.4055C7.87168 17.3426 8.00935 17.2502 8.12501 17.1336L17.7586 7.49998C17.8747 7.3839 17.9668 7.24609 18.0296 7.09442C18.0925 6.94274 18.1248 6.78017 18.1248 6.616C18.1248 6.45182 18.0925 6.28925 18.0296 6.13758C17.9668 5.9859 17.8747 5.84809 17.7586 5.73201ZM7.24141 16.25H3.75001V12.7586L10.625 5.88358L14.1164 9.37498L7.24141 16.25ZM15 8.49061L11.5086 4.99998L13.3836 3.12498L16.875 6.61561L15 8.49061Z" fill="#6B7280"/>
//                                                     </svg>
//                                                     <svg  onClick={() => handleDeleteClick(booking)}style={{cursor:'pointer'}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                         <path d="M16.875 3.75H3.125C2.95924 3.75 2.80027 3.81585 2.68306 3.93306C2.56585 4.05027 2.5 4.20924 2.5 4.375C2.5 4.54076 2.56585 4.69973 2.68306 4.81694C2.80027 4.93415 2.95924 5 3.125 5H3.75V16.25C3.75 16.5815 3.8817 16.8995 4.11612 17.1339C4.35054 17.3683 4.66848 17.5 5 17.5H15C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25V5H16.875C17.0408 5 17.1997 4.93415 17.3169 4.81694C17.4342 4.69973 17.5 4.54076 17.5 4.375C17.5 4.20924 17.4342 4.05027 17.3169 3.93306C17.1997 3.81585 17.0408 3.75 16.875 3.75ZM15 16.25H5V5H15V16.25ZM6.25 1.875C6.25 1.70924 6.31585 1.55027 6.43306 1.43306C6.55027 1.31585 6.70924 1.25 6.875 1.25H13.125C13.2908 1.25 13.4497 1.31585 13.5669 1.43306C13.6842 1.55027 13.75 1.70924 13.75 1.875C13.75 2.04076 13.6842 2.19973 13.5669 2.31694C13.4497 2.43415 13.2908 2.5 13.125 2.5H6.875C6.70924 2.5 6.55027 2.43415 6.43306 2.31694C6.31585 2.19973 6.25 2.04076 6.25 1.875Z" fill="#EF4444"/>
//                                                     </svg>
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                     );
//                                 })
//                             ) : (
//                                 <tr>
//                                     <td colSpan="8" className="px-6 py-4 text-center text-gray-500" style={{ height: '300px' }}>
//                                         <div className="flex flex-col items-center justify-center h-full">
//                                             <svg className="w-12 h-12 mb-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
//                                             </svg>
//                                             <p className="text-lg font-semibold">No bookings found</p>
//                                             <p className="text-sm text-gray-500">Try adjusting your search criteria</p>
//                                         </div>
//                                     </td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>
//                 </div>
//                 </div>

              
//             </div>
    
//             <EditingBookingModal 
//                 isOpen={isEditModalOpen} 
//                 onClose={handleCloseModal} 
//                 booking={editingBooking} 
//                 onSave={handleSaveEdit} 
//             />

// <DeleteModal
//                 isOpen={isDeleteModalOpen}
//                 onClose={() => setIsDeleteModalOpen(false)}
//                 onDelete={deleteFunction}
//                 itemName={deleteItemName}
//             />
//             </>
//         );
//     };
    
//     export default ScrollableAdminBookingTable;
import React, { useEffect, useRef, useState, useCallback } from 'react';
import styles from './CustomScrollbar.module.css';
import SortButton from '../Buttons/Sortdate';
import EditingBookingModal from './EditingModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import DeleteModal from '../Modals/DeleteModal'; 
import {fetchAllBookings, updateBooking, deleteBooking} from '../firebase/services/bookingsData';
import { Link } from 'react-router-dom';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const ScrollableAdminBookingTable = ({onEditClick, onDeleteClick}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [selectedAmenity, setSelectedAmenity] = useState("All Amenities");
    const [isAmenityDropdownOpen, setIsAmenityDropdownOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteItemName, setDeleteItemName] = useState('');
    const [deleteFunction, setDeleteFunction] = useState(null);
    const [amenities, setAmenities] = useState(["All Amenities", "Swimming pool", "Gym", "Multi-Purpose Court", "Jacuzzi & Spa", "Indoor Theatre", "Movie Lawn", "Table Tennis Room", "Banquet Hall"]);
    
    const [searchTerm, setSearchTerm] = useState("");
    const [sortedBookings, setSortedBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState(null);
    
    const amenityDropdownRef = useRef(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingBooking, setEditingBooking] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
    const searchRef = useRef(null);
    const amenityRef = useRef(null);
    const sortDateRef = useRef(null);
    const tableContainerRef = useRef(null);

    const itemsPerPage = 5;
    const totalPages = Math.ceil(filteredBookings.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredBookings.slice(indexOfFirstItem, indexOfLastItem);
    const isAllSelected = currentItems.length > 0 && selectedRows.length === currentItems.length;

    // Fetch bookings on mount
    useEffect(() => {
      fetchAllBookings((fetchedBookings) => {
        console.log(fetchedBookings);
        
        setBookings(fetchedBookings);
        setFilteredBookings(fetchedBookings);
      });
    }, []);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
          setIsSearchDropdownOpen(false);
        }
        if (amenityRef.current && !amenityRef.current.contains(event.target)) {
          setIsAmenityDropdownOpen(false);
        }
        if (sortDateRef.current && !sortDateRef.current.contains(event.target)) {
          // Assuming SortButton has a method to close its dropdown
          sortDateRef.current.closeDropdown();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const filterBookings = useCallback((term, amenity) => {
      let filtered = bookings;

      if (term) {
        filtered = filtered.filter((booking) =>
          `${booking.userFirstName} ${booking.userLastName}`
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          booking.amenityName?.toLowerCase().includes(term.toLowerCase())
        );
      }

      if (amenity && amenity !== "All Amenities") {
        filtered = filtered.filter(
          (booking) => booking.amenityName?.toLowerCase() === amenity.toLowerCase()
        );
      }

      setFilteredBookings(filtered);
      setCurrentPage(1);
    }, [bookings]);

    useEffect(() => {
      filterBookings(searchTerm, selectedAmenity);
    }, [filterBookings, searchTerm, selectedAmenity]);

    const handleDeleteSelected = () => {
      setDeleteItemName('all selected bookings');
      setDeleteFunction(() => async () => {
        try {
          for (const index of selectedRows) {
            const bookingId = filteredBookings[index].id;
            await deleteBooking(bookingId);
          }
          fetchAllBookings(setBookings);
          setSelectedRows([]);
          toast.success('Selected bookings deleted successfully');
          setIsDeleteModalOpen(false);
        } catch (error) {
          toast.error('Failed to delete selected bookings');
        }
      });
      setIsDeleteModalOpen(true);
    };

    const handleEditClick = (booking) => {
      setEditingBooking(booking);
      setIsEditModalOpen(true);
    };

    const handleDeleteClick = (booking) => {
      const firstName = booking.userDetails?.firstName || 'Unknown';
      const lastName = booking.userDetails?.lastName || 'User';
      setDeleteItemName(`booking for ${firstName} ${lastName}`);
      setDeleteFunction(() => async () => {
        try {
          await deleteBooking(booking.id);
          setBookings(bookings.filter((b) => b.id !== booking.id));
          toast.success("Booking deleted successfully");
          setIsDeleteModalOpen(false);
        } catch (error) {
          toast.error("Failed to delete booking");
        }
      });
      setIsDeleteModalOpen(true);
    };

    const handleSaveEdit = async (updatedBooking) => {
      try {
        await updateBooking(updatedBooking.id, updatedBooking);
        fetchAllBookings(setBookings);  // Refetch bookings after update
        setIsEditModalOpen(false);
      } catch (error) {
        toast.error('Failed to update booking');
      }
    };

    const handleCloseModal = () => {
      setIsEditModalOpen(false);
      setEditingBooking(null);
      setEditingIndex(null);
    };

    const truncateText = (text, charLimit = 20) => {
      if (text.length <= charLimit) {
        return text;
      }
      
      // Find the last space within the character limit
      const lastSpace = text.lastIndexOf(' ', charLimit);
      
      // If there's a space within the limit, cut at that space
      if (lastSpace > 0) {
        return text.slice(0, lastSpace) + '...';
      }
      
      // If there's no space (it's one long word), just cut at the character limit
      return text.slice(0, charLimit) + '...';
    };

    // Helper function to parse dates
    const parseDate = (dateValue) => {
      if (dateValue instanceof Date) {
        return dayjs(dateValue);
      }
      if (typeof dateValue === 'object' && dateValue.seconds) {
        // Firestore Timestamp
        return dayjs(dateValue.toDate());
      }
      if (typeof dateValue === 'string') {
        return dayjs(dateValue);
      }
      console.error('Unrecognized date format:', dateValue);
      return null;
    };

    const handleClickOutside = (event) => {
      if (amenityDropdownRef.current && !amenityDropdownRef.current.contains(event.target)) {
        setIsAmenityDropdownOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const getAmenityStyle = (amenity) => {
      switch (amenity.toLowerCase()) { // Use toLowerCase to ensure case-insensitive matching
        case 'swimming pool':
          return { backgroundColor: 'var(--Blue-25, #EFF6FF)', color: 'var(--Blue-700, #1E40AF)' };
        case 'gym':
          return { backgroundColor: 'var(--Rose-25, #FFF1F2)', color: 'var(--Rose-700, #9F1239)' };
        case 'multi-purpose court':
          return { backgroundColor: 'var(--Emerald-25, #F0FDF4)', color: 'var(--Emerald-700, #166534)' };
        case 'banquet hall':
          return { backgroundColor: 'var(--Purple-25, #FAF5FF)', color: 'var(--Purple-700, #6B21A8)' };
        case 'party hall':
          return { backgroundColor: 'var(--Lime-25, #F7FEE7)', color: 'var(--Lime-500, #65A30D)' };
        case 'jacuzzi & spa':
          return { backgroundColor: 'var(--Cyan-25, #ECFEFF)', color: 'var(--Cyan-700, #155E75)' };
        case 'indoor theatre':
          return { backgroundColor: 'var(--Pink-25, #FDF2F8)', color: 'var(--Pink-700, #9D174D)' };
        case 'movie lawn':
          return { backgroundColor: 'var(--Orange-25, #FFF7ED)', color: 'var(--Orange-700, #9A3412)' };
        case 'table tennis room':
          return { backgroundColor: 'var(--Yellow-25, #FEFCE8)', color: 'var(--Yellow-700, #854D0E)' };
        default:
          return { backgroundColor: '#F3F4F6', color: '#4B5563' }; // Default styling
      }
    };

    // Custom scrollbar styles
    const customScrollbarStyle = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 2px;
        height: 6px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `;

    // Add new styles for responsiveness
    const responsiveStyles = `
      @media (max-width: 1024px) {
        .table-container {
          overflow-x: auto;
        }
        .responsive-table {
          min-width: 800px;
        }
        .responsive-header {
          flex-direction: column;
          align-items: flex-start;
        }
        .responsive-header > div {
          margin-bottom: 1rem;
        }
        .responsive-dropdown {
          width: 100%;
          max-width: 242px;
        }
      }
      @media (max-width: 640px) {
        .responsive-header {
          padding: 1rem;
        }
        .responsive-search {
          width: 100%;
        }
        .responsive-buttons {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .responsive-buttons > * {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    `;
  
    return (
      <>
        <style>{customScrollbarStyle}</style>
        <style>{responsiveStyles}</style>
        <div className="mt-1 bg-white border rounded-2xl overflow-hidden flex flex-col" style={{ position: 'relative', zIndex: 1 }}>
          <div className="bg-white">
            <div className="flex justify-between items-center p-6">
              <div className="flex justify-between items-center">
                <div className="text-gray-900">
                  <h1 className="font-bold" style={{color: 'var(--Gray-900, #030712)', fontStyle:'normal', fontWeight:'700', lineHeight:'24px',fontSize:'16px'}}>Recent Booking</h1>
                  <span className="text-gray-400 text-xs" style={{color: 'var(--Gray-400, #6B7280)', fontStyle:'normal', fontWeight:'400', lineHeight:'18px',fontSize:'12px'}}>
                    View all your recent booking
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <Link to="/booking" className="text-xs mr-3 font-semibold text-gray-700 underline flex items-center" style={{color: 'var(--Gray-900, #030712)', fontStyle:'normal', fontWeight:'600', lineHeight:'18px',fontSize:'12px'}}>
                  View all
                  <svg className="h-4 w-2 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          
          {/* Table container with synchronized horizontal scrolling */}
          <div className="overflow-x-auto custom-scrollbar" ref={tableContainerRef}>
            <table className="w-full text-sm text-gray-500 min-w-[800px]">
              <thead className="text-xs text-gray-500 bg-gray-50" style={{fontSize:'12px'}}>
                <tr className="border-y">
                  <th scope="col" className="p-4 w-[2%]"></th>
                  <th scope="col" className="py-4 px-6 text-left" style={{fontSize:'12px',width:'20%'}}>Name</th>
                  <th scope="col" className="px-6 py-4 w-[20%]">
                    <div className="flex items-center">
                      Slot time
                      <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M8.66699 3.66667V12M8.66699 12L4.66699 8M8.66699 12L12.667 8" stroke="#4B5563" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-4 text-left w-[20%]">
                    <div className="flex items-center">
                      Slot date
                      <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M8.66699 3.66667V12M8.66699 12L4.66699 8M8.66699 12L12.667 8" stroke="#4B5563" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-4 text-left w-[20%]">Amenities</th>
                  <th scope='col' className="px-6 py-4 text-right w-[18%]">
                    <span 
                      style={{
                        visibility: selectedRows.length > 1 ? 'visible' : 'hidden',
                        cursor: 'pointer',
                        color: '#EF4444'
                      }}
                      onClick={handleDeleteSelected}
                    >
                      Delete All
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredBookings.length > 0 ? (
                  currentItems.map((booking, index) => {
                    const actualIndex = indexOfFirstItem + index;
                    const isSelected = selectedRows.includes(actualIndex);
                    // Safely access userDetails properties
                    const firstName = booking.userDetails?.firstName || 'N/A';
                    const lastName = booking.userDetails?.lastName || 'N/A';
                    const fullName = `${firstName} ${lastName}`;
                    
                    return (
                      <tr key={booking.id} className="bg-white border-b hover:bg-gray-50" style={{fontSize:'14px'}}>
                        <td className="p-4 w-[2%]">
                          <div className="flex items-center justify-center">
                            {/* Checkbox would go here */}
                          </div>
                        </td>
                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap text-left w-[20%]">
                          {truncateText(fullName, 20)}
                        </th>
                        <td className="px-6 py-4 text-left w-[20%]">
                          {booking.timeSlot}
                        </td>
                        <td className="px-6 py-4 text-left w-[20%]">
                          {booking.date ? dayjs(booking.date.toDate()).format('D MMM, YYYY') : ''}
                        </td>
                        <td className="px-6 py-4 text-left w-[20%] whitespace-nowrap">
                          <span
                            className="inline-flex px-4 py-2 items-center font-semibold text-xs leading-4 rounded-xl no"
                            style={{ ...getAmenityStyle(booking.amenityName), fontSize: '12px', borderRadius: '13px' }}
                          >
                            {booking.amenityName}
                          </span>
                        </td>
                        <td className="px-6 py-4 text-right w-[18%]">
                          <div className="flex justify-end space-x-4">
                            <svg onClick={() => handleEditClick(booking)} style={{cursor:'pointer'}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17.7586 5.73201L14.268 2.24061C14.1519 2.1245 14.0141 2.0324 13.8624 1.96957C13.7107 1.90673 13.5482 1.87439 13.384 1.87439C13.2198 1.87439 13.0572 1.90673 12.9056 1.96957C12.7539 2.0324 12.6161 2.1245 12.5 2.24061L2.86641 11.875C2.74983 11.9906 2.65741 12.1283 2.59451 12.28C2.5316 12.4317 2.49948 12.5944 2.50001 12.7586V16.25C2.50001 16.5815 2.6317 16.8994 2.86612 17.1339C3.10054 17.3683 3.41849 17.5 3.75001 17.5H7.24141C7.40563 17.5005 7.5683 17.4684 7.71999 17.4055C7.87168 17.3426 8.00935 17.2502 8.12501 17.1336L17.7586 7.49998C17.8747 7.3839 17.9668 7.24609 18.0296 7.09442C18.0925 6.94274 18.1248 6.78017 18.1248 6.616C18.1248 6.45182 18.0925 6.28925 18.0296 6.13758C17.9668 5.9859 17.8747 5.84809 17.7586 5.73201ZM7.24141 16.25H3.75001V12.7586L10.625 5.88358L14.1164 9.37498L7.24141 16.25ZM15 8.49061L11.5086 4.99998L13.3836 3.12498L16.875 6.61561L15 8.49061Z" fill="#6B7280"/>
                            </svg>
                            <svg onClick={() => handleDeleteClick(booking)} style={{cursor:'pointer'}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.875 3.75H3.125C2.95924 3.75 2.80027 3.81585 2.68306 3.93306C2.56585 4.05027 2.5 4.20924 2.5 4.375C2.5 4.54076 2.56585 4.69973 2.68306 4.81694C2.80027 4.93415 2.95924 5 3.125 5H3.75V16.25C3.75 16.5815 3.8817 16.8995 4.11612 17.1339C4.35054 17.3683 4.66848 17.5 5 17.5H15C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25V5H16.875C17.0408 5 17.1997 4.93415 17.3169 4.81694C17.4342 4.69973 17.5 4.54076 17.5 4.375C17.5 4.20924 17.4342 4.05027 17.3169 3.93306C17.1997 3.81585 17.0408 3.75 16.875 3.75ZM15 16.25H5V5H15V16.25ZM6.25 1.875C6.25 1.70924 6.31585 1.55027 6.43306 1.43306C6.55027 1.31585 6.70924 1.25 6.875 1.25H13.125C13.2908 1.25 13.4497 1.31585 13.5669 1.43306C13.6842 1.55027 13.75 1.70924 13.75 1.875C13.75 2.04076 13.6842 2.19973 13.5669 2.31694C13.4497 2.43415 13.2908 2.5 13.125 2.5H6.875C6.70924 2.5 6.55027 2.43415 6.43306 2.31694C6.31585 2.19973 6.25 2.04076 6.25 1.875Z" fill="#EF4444"/>
                            </svg>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-center text-gray-500" style={{ height: '300px' }}>
                      <div className="flex flex-col items-center justify-center h-full">
                        <svg className="w-12 h-12 mb-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <p className="text-lg font-semibold">No bookings found</p>
                        <p className="text-sm text-gray-500">Try adjusting your search criteria</p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <EditingBookingModal 
          isOpen={isEditModalOpen} 
          onClose={handleCloseModal} 
          booking={editingBooking} 
          onSave={handleSaveEdit} 
        />

        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={deleteFunction}
          itemName={deleteItemName}
        />
      </>
    );
};

export default ScrollableAdminBookingTable;