import React, { useEffect, useState } from "react";
import styles from './CustomScrollbar.module.css';
import "../App.css";
import "../index.css";
import DocumentTable from "../Tables/DocumentsTable";
import AddDocumentsModal from "./Modal_for_Document";
import DemandLetterTable from "../Tables/DemandLetterTable";
import { fetchUserDocuments } from '../firebase/services/documents';
import ExportModal from "./DocumentExport";
import { AddIcon, ExportIcon, HighlightButton } from "./HighLightButton";

const Document = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Agreement");
  const [agreementDocuments, setAgreementDocuments] = useState([]);
  const [demandLetterDocuments, setDemandLetterDocuments] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);



   // Track window size for responsive design
   useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    // Determine if we're on mobile or tablet
    const isMobile = windowWidth < 640;
    const isTablet = windowWidth >= 640 && windowWidth < 1024;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);



  const getHeaderContent = () => {
    return activeTab === "Agreement" 
      ? { title: "Documents", subtitle: "Keep track of all your documents in one place." }
      : { title: "Demand Letters", subtitle: "Manage and track all your demand letters efficiently." };
  };

  const { title, subtitle } = getHeaderContent();

  const loadDocuments = async () => {
    try {
      const userId = '2352032552'; // Replace with actual user ID from your auth system
      const docs = await fetchUserDocuments(userId);
      console.log("All docs", docs);
      
      // Separate documents based on type
      const agreements = docs.filter(doc => doc.documentType === 'Agreement for sale');
      const demandLetters = docs.filter(doc => doc.documentType === 'Demand letters');
      
      setAgreementDocuments(agreements);
      setDemandLetterDocuments(demandLetters);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  const handleDocumentAdded = () => {
    loadDocuments();
  };

  const getCurrentDocuments = () => {
    return activeTab === "Agreement" ? agreementDocuments : demandLetterDocuments;
  };

  const handleCloseModal = () => {
    setShowExportModal(false);
  };

  return (
    <div className={`p-2 lg:p-0 lg:ml-10 ${isModalOpen ? 'bg-opacity-50' : ''}`}>
     <div className={`${isMobile ? 'flex-col space-y-4' : 'flex justify-between items-center'}`}>
        <div className={isMobile ? 'mb-2 text-start' : ''}>
          <h1 
            className={`${styles.customScrollbar} text-gray-900 font-bold`}
            style={{
              fontSize: isMobile ? "20px" : "24px",
              lineHeight: isMobile ? "28px" : "32px"
            }}
          >
            {title}
          </h1>
          <p 
            className="gap-2"
            style={{
              fontSize: isMobile ? "15px" : "17px",
              color: "var(--Gray-400, #6B7280)",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: isMobile ? "24px" : "28px"
            }}
          >
            {subtitle}
          </p>
        </div>
        <div className={`
          ${isMobile 
            ? 'flex   w-full justify-between' 
            : 'flex items-center space-x-2'
          }
        `}>
          <HighlightButton 
            type="secondary" 
            onClick={() => setShowExportModal(true)}
            className={isMobile ? 'w-full' : ''}
          >
            <ExportIcon />
            {isMobile ? 'Export' : 'Export'}
          </HighlightButton>

          <HighlightButton 
            type="primary"
            onClick={openModal}
            className={isMobile ? 'w-full' : ''}
          >
            <AddIcon />
            {activeTab === "Agreement" ? "Add Document" : "Add Demand"}
          </HighlightButton>
        </div>
      </div>

      <div className="flex mt-8 relative">
        <div className="flex gap-6 border-b border-gray-200 w-full">
          <button
            style={{fontSize:'14px',fontFamily: "Plus_Jakarta",}}
            className={`pb-2 font-semibold relative ${
              activeTab === "Agreement" ? "text-gray-900" : "text-gray-400"
            }`}
            onClick={() => setActiveTab("Agreement")}
          >
            Agreement for sale
            {activeTab === "Agreement" && (
              <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gray-900"></div>
            )}
          </button>
          <button
            style={{fontSize:'14px',fontFamily: "Plus_Jakarta",}}
            className={`pb-2 font-semibold relative ${
              activeTab === "DemandLetter" ? "text-gray-900" : "text-gray-400"
            }`}
            onClick={() => setActiveTab("DemandLetter")}
          >
            Demand letters
            {activeTab === "DemandLetter" && (
              <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gray-900"></div>
            )}
          </button>
        </div>
      </div>

      <div className="mt-6 rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          {activeTab === "Agreement" ? <DocumentTable /> : <DemandLetterTable />}
        </div>
      </div>

      <AddDocumentsModal isOpen={isModalOpen} onClose={closeModal} onDocumentAdded={handleDocumentAdded} isMobile={isMobile} />

      {showExportModal && (
        <ExportModal
          documents={getCurrentDocuments()}
          documentType={activeTab === "Agreement" ? "Agreement for sale" : "Demand letters"}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Document;